import request from "@/api/request";
import baseURL from "../index";
import * as apiCode from "../apiCode";
// import Qs from 'qs'

/// 获取分类
export function fetchCategory(data) {
  return request({
    url: baseURL + apiCode.getDtkCategory,
    data: data
  });
}
/// 获取商品
export function fetchGoods(data) {
  return request({
    url: baseURL + apiCode.getDtkGoods,

    // 传params或用Qs.stringify转换后，可以以queryString形式发送
    // data则以payload形式发送
    // data: Qs.stringify(data),
    params: data 
  });
}
/// 获取商品详情
export function fetchGoodsDetail(data){
  return request({
    url: baseURL + apiCode.getGoods,

    // 传params或用Qs.stringify转换后，可以以queryString形式发送
    // data则以payload形式发送
    // data: Qs.stringify(data),
    params: data 
  });
}
