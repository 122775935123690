<!--商品详情右侧更多爆料-->
<template>
  <div>
    <div class="mt-20 bg-white" style="padding:20px 0px 15px 0px">
      <div style="margin-left:20px;margin-bottom:22px;font-size:16px;color:#333333;font-weight:700"><span>更多爆料</span></div>
      <!--分割线-->
      <div>
       <div style="width: 100%; height: 1px; background-color: #EEEEEE"></div>
      </div>
        <div class="item-list full-width box-border" style="overflow: auto;" >
          <div v-for="(e,i) in list" :key="i">
            <MoreBLItem :item="e" :category="category"/>
            <div v-if="i<(list.length-1)" class="pl-25 pr-25">
              <div style="width: 100%; height: 1px; background-color: #f8f8f8"></div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>

import {fetchList} from '@/api/modules/baoliao'
import MoreBLItem from './MoreBLItem.vue'
export default {
    name: 'DetailMoreBL',
    props:{
      category:String
    },
    data() {
        return {
          tagId:"",
          listParams: {
            pageNo: 1,
            pageSize: 10,
            userId: 5,
            tagId: 199,
          },
          list: [],
          loading:false,
        }
    },
    components:{MoreBLItem},
    created(){
      this.queryData()
    },
    computed:{
    },
    methods:{
      queryData() {
      var self = this

      this.loading = true
      this.listParams["category"] = this.category
      this.list = []
      fetchList(this.listParams).then((res) => {
        if(res.code == 200 && res.data) {
          self.list = res.data.list
        }
        this.loading = false;
      })
    },


    },
}
</script>

<style>

</style>