<template>
   <AppPage :name="'home'">
     <div class="main">

      

      <div v-if="contentshow" class="content">

        <!-- 商品的 -->
        <div v-if="card.type==1">
          <div class="baoliao-item flex flex-main-between pl-25 pr-25 pt-20 pb-35">
          <div class="mr-20"><img :src="goodsData.pic" alt="" class="block" style="width: 30rem; height: 30rem;border-radius: 3px;"></div>
          <div class="flex flex-column" style="width: calc(100% - 20rem)">
            <div class="text-4a fs-18 fw-700 mt-10 flex"><div v-if="platformName != ''" class="lh-15" style="height: 1.5rem;margin-top:3px; background-color:#FF4c61;padding:2px 5px;font-size:12px;color:white;margin-right:5px;border-radius: 3px;">{{platformName}}</div><div class="lh-25">{{goodsData.title}}</div></div>
            <div class="">
              <div style="background-color: #FFF1F1;padding: .8rem 1rem; border-radius: .5rem;" class="text-primary fs-18 lh-18 inline-block mb-25 mt-30">{{this.card.desc}}</div>
              <div class="flex">
                <!-- 到手价 -->
                <div style="line-height:3.5rem" class="fs-30 fw-700 text-primary mb-5" v-if="goodsData.couponPrice != ''">  
                  <div class="flex" style="height:3.5rem">
                    <div><div class="fs-14 inline-block">¥</div><div class="fs-30 inline-block"> {{goodsData.couponPrice}}</div></div> 
                  </div> 
                </div>
                <!-- 日常价 -->
                <div   style="height:2.4rem; margin-left:1rem;font-weight:400;font-size:10px;color:#FF4C61;border-width:1px;border-radius:.5rem;border-style:solid;border-color:#FF4C61;inline-block;padding:4px 10px 3px;">
                  <div style="line-height:10px;margin-bottom:0.3rem;">日常价</div>
                  <div style="line-height:11px;margin: 0 auto; text-align: center;font-size:11px">¥ {{goodsData.zkFinalPrice2}}</div>
                </div>
                <!-- 返利 -->
                <div   v-if="goodsData.tkPriceGaoyong != ''"  style="height:2.4rem;background-color:#ff4c61; margin-left:1rem;font-weight:400;font-size:10px;color:white;border-radius:.5rem;inline-block;padding:2px 10px 7px;">
                  <div style="margin-bottom:0.1rem;font-size:9px" class="inline-block">
                    <img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664419442153389023.png" style="width:11px;height:10px;margin-right:3px">
                    <span style="font-size:9px;font-weight:600">返现</span>
                  </div>
                  <div style="line-height:11px;margin: 0 auto; text-align: center;font-size:11px">¥ {{goodsData.tkPriceGaoyong}}</div>
                </div>
              </div>
              

              

              <div class="flex flex-main-end flex-cross-end" style="margin-bottom:2rem;margin-top:6.3rem;position:relative;">
                <div>
                  
                </div>

                <a :href="card.clickUrl" target="_blank" style="margin-right:10px">
                  <div style="background-image: linear-gradient(to right,#FF7D36,#FF4040); width: 12rem; height: 3.4rem;line-height: 3.4rem; border-radius: 2rem;" class="flex flex-cross-center flex-main-center">
                    <div class="text-center text-white fs-16 ">立即抢购</div>
                  </div>
                </a>

                <a :href="card.clickUrl" target="_blank">
                  <div @mouseover="mouseOver" @mouseout="mouseOut" style="background-color: #FF0520; width: 17rem; height: 3.4rem;line-height: 3.4rem; border-radius: 2rem;" class="flex flex-cross-center flex-main-center">
                    <div class="mr-5"><img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1646277672623698003.png" class="block" style="width: 2rem; height: 2rem;" alt=""></div>
                    <div class="text-center text-white fs-16 " >手机扫码购买</div>
                  </div>
                </a>
                
                <!-- 二维码 -->
                <div :style="'display:'+showblock" style="position:absolute;right:17rem;top:-60px;width:15rem;height:15rem;background-color:white;padding:10px;border-radius: 3px;box-shadow: 0px 0px 5px 5px rgba(33, 33, 33, 0.1);">
                  <div  class="qrcode" ref="qrCodeUrl"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mr-30 ml-30" v-html="this.goodsData.descHtml">

        </div>
        <div class="pl-25 pr-25">
                  <div style="width: 100%; height: 1px; background-color: #EEEEEE"></div>
        </div>
        <div class="fs-16 text-333 fw-700 mt-30 mb-30 pl-30 flex" style="line-height:2.1rem"><div style="width:4px;height:15px;background-color: #FF4C61;margin-right:5px;margin-top:3px"></div><div><strong>宝贝详情</strong></div></div>
        <div class="pl-30 pr-30">
          <img :src="item" alt="" class="block full-width" v-for="(item, i) in detailImages" :key="i" />
        </div>

        </div>
        <!--原创清单-->
        <div v-else-if="card.type==2">
          <div class="baoliao-item flex flex-main-between pl-25 pr-25 pt-20 pb-35">
          <div class="mr-20"><img :src="card.img" alt="" class="block" style="width: 30rem; height: 30rem;border-radius: 3px;"></div>
          <div class="flex flex-column" style="width: calc(100% - 20rem)">
            <div class="text-4a fs-18 fw-700 mt-10 flex"><div v-if="platformName != ''" class="lh-15" style="height: 1.5rem;margin-top:3px; background-color:#FF4c61;padding:2px 5px;font-size:12px;color:white;margin-right:5px;border-radius: 3px;">{{platformName}}</div><div class="lh-25">{{card.title}}</div></div>
            <div class="">
              <div style="background-color: #FFF1F1;padding: .8rem 1rem; border-radius: .5rem;" class="text-primary fs-18 lh-18 inline-block mb-25 mt-30">{{card.desc}}</div>
              </div>
            </div>
          </div>
          <div class="mr-30 ml-30" ref="ycqdh5" id="ycqdh5" v-html="card.h5">
          </div>
          <div class="pl-25 pr-25">
            <div style="width: 100%; height: 1px; background-color: #EEEEEE"></div>
          </div>
        </div>
      </div>

      <div v-else>
        <Loading/>
      </div>

    </div>
    <div class="ml-20"></div>
    <div class="side">
      <AppQRCode />
      <MoreBL :category="card.category"/>
      <Copyright />
      
    </div>
   </AppPage>
</template>
<script>
import Loading from '../home/components/Loading/Loading.vue'
import { fetchGoodsDetail } from "@/api/modules/hotsale";
import { AppId, userChannelId } from "@/constants";
import MoreBL from "./MoreBL.vue"
import QRCode from 'qrcodejs2'
// import Base64 from 'js-base64'

export default {
  name: "Detail",
  props: {
    
  },
  components:{Loading,MoreBL},
  data() {
     return {
       contentshow:false,
       card: this.$route.query,
       detailImages: [],
       goodsData:{},
       platformName:"",
       showblock:"none",
       isCreateQR:false,
     }
  },
  created(){
    
    console.log(this.card)
  },
  computed:{
  },
  methods:{
    getPlatformName( platform,istianmao){
      if(platform==1 && istianmao==1){
        return "天猫"
      }else if(platform==2){
        return "京东"
      }else if(platform==3){
        return "拼多多"
      }else if(platform==6){
        return "唯品会"
      }else if(platform==7){
        return "苏宁"
      }else if(platform==13){
        return "抖音"
      }else{
        return "";
      }
    },
    // 返回商品分享的url
     makeShareGoodsURL(){
      var query_url = "&"+"5"+"&"+ Date.now()+"&"+this.goodsData.num_iid+"&"+this.goodsData.goodsSign;
      var query_url_base64 = this.$Base64.encode(query_url)
      var url_encode = "http://sq986.top/dayshare?data="+query_url_base64+"&type=1&userChannelId=6&appId=2&isLogin=1"
      return url_encode;
    },
    /// 鼠标滑入手机扫码购买
    mouseOver(){
      this.creatQrCode()
      console.log("mouseOver");
      this.showblock = "block"
    },
    /// 鼠标滑出手机扫码购买
    mouseOut(){
      console.log("mouseOut");
       this.showblock = "none"
    },
    
    /// 创建分享二维码
    creatQrCode() {
        var url = this.makeShareGoodsURL()
        console.log("url:"+url);
        if(this.isCreateQR==false){
          this.isCreateQR = true;
        }else{
          return;
        }
        new QRCode(this.$refs.qrCodeUrl, {
            text: url, // 需要转换为二维码的内容
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
  },
  mounted:function(){
    window.scrollTo(0,0);

    if(this.card.type==1){
      fetchGoodsDetail({
              appId: AppId,
              userId: 5,
              userChannelId:userChannelId,
              numIid: this.card.numIid,
              goodsSign:this.card.goodsSign,
              platform:this.card.platform,
              itemUrl:this.card.itemUrl,
            }).then((resGood) => {
              if (resGood.code == 200 && resGood.data ) {
                this.goodsData = resGood.data;
                this.detailImages = this.goodsData.small_pic_arr
                console.log("getgoods"+this.goodsData);
                this.contentshow = true;
                if(this.goodsData.platform==1){
                  if(this.goodsData.isTmall==1){
                    this.platformName = this.goodsData.platformName;
                  }else{
                    this.platformName = ""
                  }
                }else{
                    this.platformName = this.goodsData.platformName;
                }
                
              }
            });
    }else if(this.card.type==2){
      this.contentshow = true;

       
        this.$nextTick(function(){
          var ycqdh5 = this.$refs.ycqdh5
          var a_tags = ycqdh5.getElementsByTagName("a");
          console.log("a tags:"+a_tags) 
          for (var i = 0; i < a_tags.length; i++)  {
              var a = a_tags[i];
              a.setAttribute("target", "_blank");
            }
        })
    }

    
  }
}
</script>
<style lang="css" scoped>
.main {
  width: 834px;
  background: white;
}
.side {
  width: 314px;
}
</style>
