<template>
  <div>

    <div v-if="card.type!=4">
      <router-link tag="a" target="_blank"   :to="{name: 'Detail', query: detailData}">
      <div class="baoliao-item flex pl-25 pr-25 pt-20 pb-20">
        <div class="mr-20"><img :src="card.img" alt="" class="block" style="width: 8rem; height: 8rem;border-radius: 3px;"></div>
        <div class="flex flex-column" style="width: calc(100%)">
          <div class="text-4a fs-15 fw-300 lh-20" style="color:#4A4A4A">{{card.title}}</div>
          <div class="flex  " style="line-height:20px;margin-top:13px">
            <div v-if="card.price != ''"><div class="inline-block" style="color:#9B9B9B;font-size:13px;font-weight:400;margin-right:5px;text-decoration:line-through">¥ {{card.price}}</div></div>
            <div v-if="((card.handPrice != '') && (card.handPrice!=null))"><div class="inline-block" style="color:#FF4c61;font-size:18px;font-weight:700;">¥ {{card.handPrice}}</div></div>
          </div>
        </div>
      </div>
    </router-link>
      
    </div>
    <div v-else>
        <div class="baoliao-item flex pl-25 pr-25 pt-20 pb-20">
        <div class="mr-20"><img :src="card.img" alt="" class="block" style="width: 8rem; height: 8rem;border-radius: 3px;"></div>
        <div class="flex flex-column" style="width: calc(100%)">
          <div class="text-4a fs-15 fw-300 lh-20" style="color:#4A4A4A">{{card.title}}</div>
          
        </div>
      </div>
    </div>

    


  </div>
  
</template>
<script>
const tagName = {
    "1": "淘宝",
    "2": "天猫",
    "3": "拼多多"
  }
export default {
  name: "BLItem",
  props: {
    category:String,
    item: Object,
  },
  data() {
     return {
       card: {

       },
       detailData:{

       }

     }
  },
  created(){
    var item = this.$props.item

    // 3052清单类型认为是商品
    if(item.qingdanId == 3052) {
      item.styleType = 0 // 小图
      item.isValid = 1 // 未过期
      item.handPrice = parseFloat(Number(item.zkFinalPrice - item.denominations).toFixed(2))
      item.tag = tagName[item.platform]
      item.huangtiao = item.couponInfo
      item.goods = {
        title: item.title,
        num_iid: item.numIid,
        zk_final_price: item.zkFinalPrice,
        item_url: item.item_url,
        platform: item.platform,
        small_pic: item.small_pic,
        click_url: item.item_url
      }
     
    }

    // 商品类型
    if(item.goods) {
      this.card = {
        img: item.pic || item.goods.pic,
        title: item.goods.title,
        desc: item.huangtiao,
        handPrice: item.handPrice ? item.handPrice : item.goods.handPrice,
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category,
        detailImages: item.goods.small_pic,
        clickUrl: item.goods.click_url,
        price:item.goods.zkFinalPrice
      }
      this.detailData = {
        numIid:item.goods.num_iid,
        goodsSign:item.goods.goodsSign,
        platform:item.goods.platform,
        itemUrl:item.goods.item_url,
        desc: item.huangtiao,
        category:item.category,
        type:1, // 商品的标记为1
      }
      console.log(this.detailData);
    }

    

    // 清单类型
    if(item.publishContent) {
      let pic = item.pic
      if(item.publishContent.goodsArticleIds != null && item.publishContent.goodsArticleIds.length) {
        pic = item.publishContent.goodsArticleIds[0].pic
      }
      if(item.publishContent.contentPic) {
        pic = item.publishContent.contentPic.split(",")[0]
      }
      this.card = {
        img: pic ,
        title: item.title,
        desc: item.huangtiao,
        price: '',
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category,
        category:item.category,
        h5:item.publishContent.h5Content,
        type:2,// 原创清单的标记为2
      }
      this.detailData = this.card
    }

    // 文章类型
    if(item.article) {
      let pic
      if (item.pic) {
        pic = item.pic
      } else if (item.article.imageList && item.article.imageList.length) {
        pic = item.article.imageList[0]
      }

      this.card = {
        img: pic ,
        title: item.title,
        desc: item.huangtiao,
        price: '',
        platformName: item.tag,
        dateStr: this.$moment(item.starttime).format('M月D日'),
        tags: item.category,
        category:this.category,
        type:3,// 文章的标记为3
      }
      this.detailData = this.card
    }

    // deeplink的类型
    if(item.article==null && item.publishContent==null && item.goods==null){
      this.card = {
        img: item.pic ,
        title: item.title,
        type:4,// deeplink的标记为4
      }
    }
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
